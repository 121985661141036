import { Container } from '@chakra-ui/react'
import React from 'react'

import Layout from '../containers/Layout'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1>NOT FOUND</h1>
    </Container>
  </Layout>
)

export default NotFoundPage
